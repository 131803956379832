import React, { memo, Fragment, useState, useEffect, useCallback, useRef } from "react";

//react-bootstrap
import { Button, Col, Row, Table, Modal, Form } from "react-bootstrap";

//components
import { getUserInfo, updateUser, updateUserPassword, uploadContractImage } from "../../../api/auth";
import { useNavigate, useParams } from "react-router-dom";
import UserEditLeftForm from "./component/UserEditLeftForm";
import UserEditRightForm from "./component/UserEditRightForm";
import Card from "../../../components/bootstrap/card";
import useUserLessonInfo from "../../../hooks/useUserLessonInfo";
import useUserSalesInfo from "../../../hooks/useUserSalesInfo";

import imageCompression from "browser-image-compression";

import { formatDate } from "../../../utilities/dateFormatter";
import { formatNumber } from "../../../utilities/formatNumber";
import { lessonUseStatusMapping } from "../../../mapping/salesMapping";
import useConfigProgramList from "../../../hooks/config/useConfigProgramList";
import { useLessonData } from "../../../hooks/lesson/useLessonData";
import { updateUserProgram } from "../../../api/sales";
import useUserProgramLogs from "../../../hooks/useUserProgramLogs";
import useFetchInstructorList from "../../../hooks/useFetchInstructorList";
import useUserInfo from "../../../hooks/useUserInfo";
import useUserMe from "../../../hooks/useUserMe";

const UserEdit = memo(() => {
  const { userId } = useParams();
  const { userMeData } = useUserMe();
  const storeId = userMeData?.instructor?.storeId;
  const instructorId = userMeData?.instructor?.id;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    birth: "",
    joinPath: "",
    role: "ROLE_USER",
    contractImage: null,
    isTrial: false,
    active: true,
    instructor: null,
    store: null,
    memo: "",
    userProgram: null,
    program: null,
    instructorId: instructorId,
    selectInstructorId: null
  });

  // 프로그램
  const { programList } = useConfigProgramList(storeId);
  // 결제내역
  const { userSalesInfo } = useUserSalesInfo(userId);
  // 레슨내역
  const { userLessonInfo } = useUserLessonInfo(userId);
  // 관리자 수정내역
  const { userProgramLogs } = useUserProgramLogs(userId);
  // 계약서
  const [contractView, setContractView] = useState(null);
  // 레슨 종류 및 옵션 config 연결
  const { lessonOptions } = useLessonData(userMeData?.instructor.storeId);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/user/user-list");
  };

  const { userInfoData, refresh } = useUserInfo(userId);
  useEffect(() => {
    if (userInfoData) {
      setContractView(userInfoData.contractImage);
      setFormData(userInfoData);
    }
  }, [userInfoData]); // Only run this effect when userInfoData changes  

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files : type === "checkbox" ? checked : value,
    }));
  };

  // 비밀번호 변경 
  const [password, setPassword] = useState('');
  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value)
  };
  const handlePasswordChangeUpdate = useCallback(async () => {
    const confirmDelete = window.confirm("정말로 변경하시겠습니까?");
    if (confirmDelete) {
      try {
        await updateUserPassword(userId, password);
        alert("변경되었습니다.");
        handleClose();
        setPassword('');
        refresh(); // 데이터 갱신
      } catch (error) {
        alert(error.message);
      }
    }
  }, [refresh, userId, password]);

  // 강사 검색 셀렉트
  const { instructorListOption, error: instructorError } = useFetchInstructorList(storeId);
  const [instructorSelectedData, setInstructorSelectedData] = useState(null);
  const handleInstructorSelectedChange = (target) => {
    if (target) {
      setInstructorSelectedData(target);
    } else {
      setInstructorSelectedData(null);
      setFormData({
        ...formData,
        selectInstructorId: null,
      });
    }
  };

  const handleProgramChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedProgram = {
      ...formData.userProgram,
      [name]: type === "checkbox" ? checked : value
    };
    
    setFormData((prevData) => ({
      ...prevData,
      userProgram: updatedProgram,
    }));
  }

  const handleContractImageUpload = async (e) => {
    e.preventDefault();
    const { files } = e.target;
    setLoading(true);

    try {
      // 이미지 압축
      const compressedFile = await compressImage(files[0]);
      const url = await uploadContractImage(compressedFile, formData.store.id, userId);
      formData.contractImage = url;

      setFormData(formData);
      setSuccess(true);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error("Error compressing image:", error);
      throw error;
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    // console.log(formData);
    // setLoading(false);
    // setSuccess(true);
    // return false;

    try {
      formData.instructorId = instructorId;
      if (instructorSelectedData != null) {
        formData.selectInstructorId = instructorSelectedData.value;
      }

      await updateUser(userId, formData);
      setSuccess(true);
      alert("저장되었습니다.");
      window.location.reload();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUserProgram = async (salesId, lessonUseStatus) => {
    try {
      await updateUserProgram(salesId, lessonUseStatus);
      setSuccess(true);
      alert("저장되었습니다.");
      window.location.reload();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  const handleDateChange = (selectedDates, name, isClear = false) => {
    let updatedProgram;
    if (isClear === false && selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      });
  
      const [startDate, endDate] = formattedDates;
  
      updatedProgram = {
        ...formData.userProgram,
        [`${name}StartDate`]: startDate || "",
        [`${name}EndDate`]: endDate || "",
      };
      setFormData((prevData) => ({
        ...prevData,
        userProgram: updatedProgram,
      }));
    } else if (isClear === true && selectedDates === null) {
      updatedProgram = {
        ...formData.userProgram,
        [`${name}StartDate`]: null,
        [`${name}EndDate`]: null,
      };
      setFormData((prevData) => ({
        ...prevData,
        userProgram: updatedProgram,
      }));
    }
  };

  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">비밀번호 변경</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="col-md-12 form-group">
              <Form.Label htmlFor="fname">신규 비밀번호</Form.Label>
              <Form.Control
                type="text"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handlePasswordChangeUpdate}>
            변경
          </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <Col md="12">
          <Card>
            <Card.Body className="d-flex justify-content-between align-items-center flex-wrap gap-2">
              <div className="card-title mb-0">
                <h4 className="mb-0">"{formData.name}"님 회원 정보</h4>
              </div>
              <div>
                <Button
                  type="button"
                  variant="btn btn-success"
                  onClick={handleShow}
                  disabled={loading}
                >
                  비밀번호 변경
                </Button>{" "}
                <Button
                  type="button"
                  variant="btn btn-primary"
                  onClick={handleUpdate}
                  disabled={loading}
                >
                  {loading ? "저장" : "저장"}
                </Button>{" "}
                {error && <p style={{ color: "red" }}>{error}</p>}
                {/* {success && <p style={{ color: "green" }}>Registration successful!</p>} */}
                <Button type="button" variant="btn btn-light" onClick={handleBackClick}>
                  회원목록
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <UserEditLeftForm
          formData={formData}
          contractView={contractView}
          handleChange={handleChange}
          handleUpdate={handleUpdate}
          handleContractImageUpload={handleContractImageUpload}
        />
        <UserEditRightForm
          formData={formData}
          programList={programList}
          handleProgramChange={handleProgramChange}
          handleDateChange={handleDateChange}
          handleInstructorSelectedChange={handleInstructorSelectedChange}
          lessonOptions={lessonOptions}
          instructorListOption={instructorListOption}
          selectInstructor={instructorSelectedData}
        />
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body className="d-flex justify-content-between align-items-center flex-wrap gap-2">
              <div className="card-title mb-0">
                <h4 className="mb-0">결제 내역</h4>
              </div>
              <hr />
              <Table className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th scope="col">결제일</th>
                    <th scope="col">구분</th>
                    <th scope="col">결제방법</th>
                    <th scope="col">프로그램</th>
                    <th scope="col">옵션</th>
                    <th scope="col">부여횟수(개인/그룹)</th>
                    <th scope="col">결제금액</th>
                    <th scope="col">잔금</th>
                    <th scope="col">이용상태</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userSalesInfo.map((sales, index) => (
                    <tr key={`${sales.id}`}>
                      <th scope="row">
                        {userMeData?.instructor?.position === 'DIRECTOR' ? (
                          <a href={`/sales/sales-edit/${sales.id}`}>{formatDate(sales.createdAt)}</a>
                        ) : (
                          formatDate(sales.createdAt)
                        )}
                      </th>
                      <td>{sales.saleType}</td>
                      <td>{sales.payMethod}</td>
                      <td>{sales.program && sales.program.name}</td>
                      <td>{sales.individualType}</td>
                      <td>{sales.individualCount} / {sales.groupCount}</td>
                      <td>{formatNumber(sales.payment.totalPrice)}</td>
                      <td className="text-danger">{sales.payment.receivePrice}</td>
                      <td>
                        {(sales.saleType !== '환불' && sales.payMethod !== '환불') && (
                          lessonUseStatusMapping[sales.lessonUseStatus] || sales.lessonUseStatus
                        )}
                      </td>
                      <td align="center">
                      {!sales.isStartLesson && 
                      !sales.isReceiveSales && 
                      sales.saleType !== '잔금 납부' &&
                      (sales.saleType !== '환불' && sales.payMethod !== '환불') &&
                      sales.lessonUseStatus === 'READY' && (
                        <Button
                          type="button"
                          variant="btn btn-sm btn-primary"
                          onClick={() => handleUserProgram(sales.id, 'USING')}
                          disabled={loading}
                        >
                          프로그램 시작
                        </Button>
                      )}
                      {sales.lessonUseStatus === 'USING' && (
                        <Button
                          type="button"
                          variant="btn btn-sm btn-danger"
                          onClick={() => handleUserProgram(sales.id, 'COMPLETED')}
                          disabled={loading}
                        >
                          프로그램 종료
                        </Button>
                      )}
                      </td>
                    </tr>
                  ))}
                  {userSalesInfo.length === 0 && (
                    <tr>
                      <td colSpan="9" className="text-center">
                        내역이 없습니다
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body className="d-flex justify-content-between align-items-center flex-wrap gap-2">
              <div className="card-title mb-0">
                <h4 className="mb-0">레슨 내역</h4>
              </div>
              <hr />
              <Table className="table table-sm table-bordered">
                <thead>
                  <tr>
                    {/* <th scope="col">#</th> */}
                    <th scope="col">레슨 날짜</th>
                    <th scope="col">레슨 시간</th>
                    <th scope="col">레슨 구분</th>
                    <th scope="col">레슨강사</th>
                  </tr>
                </thead>
                <tbody>
                  {userLessonInfo.map((lesson, index) => (
                    <tr key={`${lesson.lessonId}`}>
                      {/* <th scope="row">{index + 1}</th> */}
                      <td>{lesson.lessonDate}</td>
                      <td>{lesson.lessonTime}</td>
                      <td>{lesson.lessonTypeName} {lesson.lessonOption ? `(${lesson.lessonOption})` : ''}</td>
                      <td>{lesson.instructorName}</td>
                    </tr>
                  ))}
                  {userLessonInfo.length === 0 && (
                    <tr>
                      <td colSpan="5" className="text-center">
                        내역이 없습니다
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body className="d-flex justify-content-between align-items-center flex-wrap gap-2">
              <div className="card-title mb-0">
                <h4 className="mb-0">관리자 수정 내역</h4>
              </div>
              <hr />
              <Table className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th scope="col">날짜</th>
                    <th scope="col">개인 횟수 / 그룹 횟수</th>
                    <th scope="col">개인 이용기간 / 그룹 이용기간</th>
                    <th scope="col">담당강사</th>
                  </tr>
                </thead>
                <tbody>
                  {userProgramLogs.map((log, index) => (
                    <tr key={`${log.id}`}>
                      <td>{formatDate(log.createdAt)}</td>
                      <td>[개인] {log.individualCount}<br/>[그룹] {log.groupCount}</td>
                      <td>[개인] {log.individualDate}<br/>[그룹] {log.groupDate}</td>
                      <td>{log.instructorName}</td>
                    </tr>
                  ))}
                  {userProgramLogs.length === 0 && (
                    <tr>
                      <td colSpan="4" className="text-center">
                        내역이 없습니다
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

UserEdit.displayName = "UserEdit";
export default UserEdit;
