import React, { memo, Fragment, useState, useEffect } from "react";

//react-bootstrap
import { Row, Col, Button, Modal, Form, Badge, Nav, Tab } from "react-bootstrap";
import Select from "react-select";

//components
import Card from "../../../components/bootstrap/card";

//full calendar
import FullCalendar from "@fullcalendar/react";
import koLocale from "@fullcalendar/core/locales/ko";

//flatpicker
import Flatpickr from "react-flatpickr";
import { Korean } from "flatpickr/dist/l10n/ko.js"; // Import 한국어 locale

//lib
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import * as moment from "moment";

//hooks
import useLessonLists from "../../../hooks/lesson/useLessonLists";

//api
import { deleteLesson, getLesson, updateLesson } from "../../../api/lesson";
import { getUserLists, getUserMe } from "../../../api/auth";
import useUserMe from "../../../hooks/useUserMe";
import { getConfigLessonList } from "../../../api/config";
import useInstructorLists from "../../../hooks/useInstructorList";
import useInstructorLessonMemoList from "../../../hooks/lesson/useInstructorLessonMemoList";
import { updateInstructorMemo } from "../../../api/instructor";

const ScheduleList = memo(() => {
  // 레슨 모달
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // 강사메모 모달
  const [memoShow, setMemoShow] = useState(false);
  const handleMemoClose = () => setMemoShow(false);
  const handleMemoShow = () => setMemoShow(true);

  const { userMeData } = useUserMe();
  const storeId = userMeData?.instructor?.storeId;
  const myInstructor = userMeData?.instructor;
  const myInstructorId = userMeData?.instructor?.id;

  const currentYear = new Date().getFullYear();
  const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0");
  const searchDate = `${currentYear}-${currentMonth}`;
  const [transformedData, setTransformedData] = useState([]);
  const [lessonData, setLessonData] = useState({
    instructorId: "",
    storeId: "",
    count: 0,
    status: "",
    memo: "",
    lessonType: "",
    lessonTypeName: "",
    lessonOption: "",
    lessonDate: "",
    lessonTime: "",
    lessonUserList: [],
    lessonWaitUserList: [],
    selectUserData: [],
    waitUserData: [],
    noShowUserData: [],
  });
  const [userSelectData, setUserSelectData] = useState();
  const [selectedOption, setSelectedOption] = useState({
    value: "",
    label: "",
    individualCount: 0,
    individualType: "",
    groupCount: 0,
  });
  const [lessonTypes, setLessonTypes] = useState([]);
  const [lessonOptions, setLessonOptions] = useState([]);
  const [lessonDate, setLessonDate] = useState();
  const [lessonTime, setLessonTime] = useState();
  const [userSelectedData, setUserSelectedData] = useState();
  const [userWaitData, setUserWaitData] = useState();
  const [userNoShowData, setUserNoShowData] = useState();
  const [userRefresh, setUserRefresh] = useState(null);

  // 강사 조회
  const { instructorList } = useInstructorLists(storeId, null, true);
  const [sortedInstructorList, setSortedInstructorList] = useState(instructorList);
  useEffect(() => {
    if (instructorList && myInstructorId) {
      const matchingInstructor = instructorList.find(instructor => instructor.id === myInstructorId);
    
      // myInstructor의 position이 BASIC이면 다른 instructor의 position이 DIRECTOR인 강사를 제외 (원장 스케줄 조회불가)
      const remainingInstructors = instructorList.filter(instructor => {
        if (instructor.id !== myInstructorId) {
          // BASIC인 경우 DIRECTOR 제외
          if (matchingInstructor && matchingInstructor.position !== 'DIRECTOR') {
            return instructor.position !== 'DIRECTOR';
          }
          return true; // position이 BASIC이 아니면 모두 포함
        }
        return false; // 본인(myInstructorId)은 제외
      });

      const rearrangedInstructorList = matchingInstructor 
        ? [matchingInstructor, ...remainingInstructors] 
        : instructorList;

      setSortedInstructorList(rearrangedInstructorList);      
      setViewInstructorId(myInstructorId);
    }
  }, [instructorList, myInstructorId]);

  // 레슨 리스트 조회
  const [allView, setAllView] = useState(false);
  const [groupLessonView, setGroupLessonView] = useState(false);
  const [viewInstructorId, setViewInstructorId] = useState(null);
  const [isViewFetching, setIsViewFetching] = useState(false); // 요청 상태 관리
  const { lessonList, refresh } = useLessonLists(storeId, searchDate, allView, viewInstructorId, groupLessonView);

  // 강사 메모 조회 및 업데이트
  const { instructorMemoList } = useInstructorLessonMemoList(storeId, myInstructorId);
  const [instructorMemo, setInstructorMemo] = useState(null);
  const [instructorMemoData, setInstructorMemoData] = useState({
    id: "",
    instructorId: "",
    lessonMemo: ""
  });
  const handleUpdateMemoShow = async (memo, id) => {
    setInstructorMemo(memo);
    setMemoShow(true);

    setInstructorMemoData({
      id: id,
      instructorId: myInstructorId,
      lessonMemo: memo
    })
  }
  const handleUpdateInstructorMemo = async (e) => {
    instructorMemoData.lessonMemo = instructorMemo
    console.log(instructorMemoData);

    try {
      await updateInstructorMemo(instructorMemoData);
      alert("수정되었습니다.");
      window.location.reload();
    } catch (error) {
      console.error("Error deleting leave log:", error.message);
    }
  }

  // 캘린더에서 사용할 레슨 데이터 변환
  useEffect(() => {
    const transformLessonLists = (lessonLists) => {
      const transformedData = lessonLists.map((lesson) => {
        let backgroundColor, textColor, borderColor;
    
        if (lesson.lessonType === "GROUP") {
          backgroundColor = "rgba(40, 167, 69, 0.2)";
          textColor = "rgba(40, 167, 69, 1)";
          borderColor = "rgba(40, 167, 69, 1)";
        } else if (lesson.lessonType === "INDIVIDUAL") {
          backgroundColor = "rgba(0, 123, 255, 0.2)";
          textColor = "rgba(0, 123, 255, 1)";
          borderColor = "rgba(0, 123, 255, 1)";
        } else if (lesson.lessonType === "CONSULT") {
          backgroundColor = "rgba(255, 193, 7, 0.3)";
          textColor = "#f16a1b";
          borderColor = "rgba(255, 193, 7, 1)";
        }

        let lessonTitle = lesson.lessonTime;
        if (lesson.lessonType === 'INDIVIDUAL' && lesson.lessonUserList.length > 0) {
          lessonTitle += ' ' + lesson.lessonUserList.map(user => user.name).join(', ');
        } else if (lesson.lessonType === 'GROUP'){
          lessonTitle += ' 그룹';
        }
    
        return {
          id: lesson.id,
          title: lessonTitle,
          start: moment(lesson.lessonDate).format("YYYY-MM-DD"),
          end: moment(lesson.lessonDate).format("YYYY-MM-DD"),
          backgroundColor: backgroundColor,
          textColor: textColor,
          borderColor: borderColor,
        };
      });
      setIsViewFetching(false); // 요청 완료 -> 초기화
    
      return transformedData;
    };

    if (storeId != null && lessonList) {
      const newData = transformLessonLists(lessonList);
      setTransformedData(newData);
    }
  }, [lessonList, storeId]);

  // 레슨 모달 데이터
  const fetchLessonDetailData = async (eventId) => {
    try {
      const lesson = await getLesson(eventId);
      lesson.selectUserData = lesson.lessonUserList.map((user) => ({
        value: user.id, // 예시: 유저의 id를 value로 사용할 수 있음
        label: user.name, // 예시: 유저의 이름을 label로 사용할 수 있음,  + user.lessonLog.status
      }));
      lesson.waitUserData = lesson.lessonWaitUserList.map((user) => ({
        value: user.id, // 예시: 유저의 id를 value로 사용할 수 있음
        label: user.name, // 예시: 유저의 이름을 label로 사용할 수 있음
      }));
      lesson.noShowUserData = lesson.lessonNoShowUserList.map((user) => ({
        value: user.id, // 예시: 유저의 id를 value로 사용할 수 있음
        label: user.name, // 예시: 유저의 이름을 label로 사용할 수 있음
      }));
      setLessonData(lesson);
      handleShow(true);
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };
  const handleLessonDataShow = (info) => {
    const eventId = info.event.id;
    setUserSelectedData(null);
    setUserNoShowData(null);
    setUserWaitData(null);

    // 날짜&시간 데이터 초기화
    setLessonDate();
    setLessonTime();

    fetchLessonDetailData(eventId);
  };

  // 회원 검색 셀렉트
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserMe();
        setUserSelectData(userData);
        setUserRefresh(false);
      } catch (error) {
        console.error("Failed to fetch user data", error);
      }
    };
    fetchUserData();
  }, [userRefresh]);

  useEffect(() => {
    if (userSelectData && userSelectData.instructor.id) {
      const fetchUserList = async () => {
        try {
          const result = await getUserLists(userSelectData.instructor.storeId);

          const transformedOptions = result.map((user) => ({
            value: user.id,
            label: `${user.name} (${user.phone}) | ${user.userProgram?.individualCount ?? 0}/${user.userProgram?.groupCount ?? 0}`,
            individualCount: user.userProgram?.individualCount ?? 0,
            individualType: user.userProgram?.individualType ?? "",
            groupCount: user.userProgram?.groupCount ?? 0,
          }));

          setSelectedOption(transformedOptions);
        } catch (error) {
          console.error("Failed to fetch leave detail", error);
        }
      };

      fetchUserList();
    }
  }, [userSelectData]);

  // 레슨 종류 및 옵션 config 연결
  useEffect(() => {
    const fetchConfigData = async () => {
      try {
        const configData = await getConfigLessonList(userSelectData.instructor.storeId);

        // lessonType 고유 값 추출
        const types = configData.reduce((acc, item) => {
          if (!acc.some((type) => type.lessonType === item.lessonType)) {
            acc.push({ lessonType: item.lessonType, lessonTypeName: item.lessonTypeName });
          }
          return acc;
        }, []);
        setLessonTypes(types);

        // lessonOption 고유 값 추출 (null 값 제외)
        const options = [
          ...new Set(
            configData
              .map((item) => ({
                lessonOption: item.lessonOption,
                lessonType: item.lessonType,
                lessonTypeName: item.lessonTypeName,
              }))
              .filter((option) => option.lessonOption !== null && option.lessonOption !== '')
          ),
        ];
        setLessonOptions(options);
      } catch (error) {
        console.error("Failed to fetch getConfigPriceList", error);
      }
    };
    if (userSelectData && userSelectData.instructor.storeId) {
      fetchConfigData();
    }
  }, [userSelectData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLessonData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleUserSelectedChange = (target) => {
    const selectValue = [];

    target.forEach((select) => {
      selectValue.push({
        name: select.label,
        value: select.value,
        individualCount: select.individualCount, // individualCount를 추가
        individualType: select.individualType,
        groupCount: select.groupCount // groupCount를 추가
      });
    });

    setUserSelectedData(selectValue);
  };

  const handleUserWaitChange = (target) => {
    const selectValue = [];

    target.forEach((select) => {
      selectValue.push({
        name: select.label,
        value: select.value,
        individualCount: select.individualCount, // individualCount를 추가
        individualType: select.individualType,
        groupCount: select.groupCount // groupCount를 추가
      });
    });

    setUserWaitData(selectValue);
  };
  
  const handleUserNoShowChange = (target) => {
    const selectValue = [];

    target.forEach((select) => {
      selectValue.push({
        name: select.label,
        value: select.value,
        individualCount: select.individualCount, // individualCount를 추가
        individualType: select.individualType,
        groupCount: select.groupCount // groupCount를 추가
      });
    });

    setUserNoShowData(selectValue);
  };

  // Handler for date range selection
  const handleDateChange = (selectedDates) => {
    const formattedDates = selectedDates.map((date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    });
    setLessonDate(formattedDates[0]);
  };

  const handleTimeChange = (selectedDates) => {
    const selectedTime = selectedDates[0];
    const hours = selectedTime.getHours().toString().padStart(2, "0");
    const minutes = selectedTime.getMinutes().toString().padStart(2, "0");
    const formattedTime = hours + ":" + minutes;
    // formattedTime now contains the time in "H:i" format
    setLessonTime(formattedTime);
  };

  const handleScheduleEdit = async (e) => {
    e.preventDefault();

    if (lessonDate !== undefined) {
      lessonData.lessonDate = lessonDate;
    }
    if (lessonTime !== undefined) {
      lessonData.lessonTime = lessonTime;
    }

    // 선택한 회원 입력
    let userValueList = [];
    if (userSelectedData !== undefined && userSelectedData != null) {
      userSelectedData.map((user) => {
        userValueList.push(user.value);
      });
    } else {
      lessonData.lessonUserList.map((user) => {
        userValueList.push(user.id);
      });
    }

    // 대기 회원 입력
    let waitUserValueList = [];
    if (userWaitData !== undefined && userWaitData != null) {
      userWaitData.map((user) => {
        waitUserValueList.push(user.value);
      });
    } else {
      lessonData.lessonWaitUserList.map((user) => {
        waitUserValueList.push(user.id);
      });
    }

    // 노쇼 회원 입력
    let noShowUserValueList = [];
    if (userNoShowData !== undefined && userNoShowData != null) {
      userNoShowData.map((user) => {
        noShowUserValueList.push(user.value);
      });
    } else {
      lessonData.lessonNoShowUserList.map((user) => {
        noShowUserValueList.push(user.id);
      });
    }

    // 중복 값 확인
    const allValues = [...userValueList, ...waitUserValueList, ...noShowUserValueList];
    const duplicateValues = allValues.filter((value, index, arr) => arr.indexOf(value) !== index);

    if (duplicateValues.length > 0) {
      alert(`참여/대기/노쇼에 중복된 회원이 있습니다. 회원은 한 그룹에만 속해야 합니다.`);
      return false;
    }

    if (lessonData.lessonType === "") {
      alert("레슨 종류를 선택해주세요.");
      return false;
    }
    if (lessonData.lessonDate === "" || lessonData.lessonDate === undefined) {
      alert("날짜를 입력해주세요.");
      return false;
    }
    if (lessonData.lessonTime === "" || lessonData.lessonTime === undefined) {
      alert("시간을 입력해주세요.");
      return false;
    }
    if (Number(lessonData.count) === 0) {
      alert("인원제한은 최소 1명이상 되어야 합니다.");
      return false;
    }
    if (userSelectedData && Number(lessonData.count) < userSelectedData.length) {
      alert("참여인원이 설정된 인원제한보다 많습니다.");
      return false;
    }

    lessonData.instructorId = userSelectData.instructor.id;
    lessonData.storeId = userSelectData.instructor.storeId;

    //console.log(userSelectedData);
    
    //const userValueList = []
    if (userSelectedData != null) {
      userValueList = []
      for (let i = 0; i < userSelectedData.length; i++) {
        const select = userSelectedData[i];
        const userName = select.name.split('(')[0].trim();
  
        if (lessonData.lessonType === 'INDIVIDUAL' && select.individualCount === 0) {
          alert(`${userName} 회원은 잔여 개인 횟수가 없습니다.`);
          return false;
        }
        if (lessonData.lessonType === 'GROUP' && select.groupCount === 0) {
          alert(`${userName} 회원은 잔여 그룹 횟수가 없습니다.`);
          return false;
        }
        if (lessonData.lessonType === 'INDIVIDUAL' && 
            lessonData.lessonOption !== '' && 
            lessonData.lessonOption !== select.individualType) {
          alert(`${userName} 회원의 개인레슨 옵션은 ${select.individualType}입니다.`);
          return false;
        }
        userValueList.push(select.value);
      }
    }

    const formLessonData = {
      id: lessonData.id,
      instructorId: lessonData.instructorId,
      lessonType: lessonData.lessonType,
      lessonOption: lessonData.lessonOption,
      count: lessonData.count,
      status: lessonData.status,
      memo: lessonData.memo,
      lessonDate: lessonData.lessonDate,
      lessonTime: lessonData.lessonTime,
      userList: userValueList,
      waitUserList: waitUserValueList,
      noShowUserList: noShowUserValueList,
    };

    // console.log(formLessonData);
    // return false;

    try {
      await updateLesson(formLessonData);
      alert("수정되었습니다.");
      setUserRefresh();
      refresh();
      handleClose();
    } catch (error) {
      alert(error.message);
      //console.error("Error deleting leave log:", error.message);
    }
  };

  const handleScheduleDelete = async (lessonId) => {
    if (window.confirm("삭제하시겠습니까?\n(삭제된 레슨은 확인할 수 없습니다.)")) {
      try {
        await deleteLesson(lessonId);
        alert("삭제되었습니다.");
        refresh();
        handleClose();
      } catch (error) {
        alert(error.message);
        //console.error("Error deleting leave log:", error.message);
      }
    }
  };

  const handleAllView = async (e) => {
    const { checked } = e.target;
    if (!isViewFetching) {
      setIsViewFetching(true);
      setAllView(checked);
    } else {
      e.target.checked = allView;
      alert('조회중입니다.');
    }
  };
  const handleViewInstructor = async (id) => {
    setViewInstructorId(id)
  };
  const handleGroupLessonView = async (e) => {
    const { checked } = e.target;
    if (!isViewFetching) {
      setIsViewFetching(true);
      setGroupLessonView(checked);
    } else {
      e.target.checked = groupLessonView;
      alert('조회중입니다.');
    }
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Body className="d-flex justify-content-between align-items-center flex-wrap gap-2">
              <div className="card-title mb-0">
                <h4 className="mb-0">레슨 스케줄</h4>
                <Badge pill bg="success">그룹레슨</Badge>{" "}
                <Badge pill bg="primary">개인레슨</Badge>{" "}
                <Badge pill bg="warning">상담</Badge>{" "}
              </div>
              <div>
              {myInstructorId && (
                <Tab.Container defaultActiveKey={myInstructorId}>
                  <Nav variant="tabs" className="mb-3" id="nav-tab" role="tablist">
                    {sortedInstructorList.map((instructor, index) => (
                      <Nav.Link
                        key={instructor.id}
                        eventKey={instructor.id}
                        aria-selected="false"
                        variant="d-flex align-items-center"
                        type="button"
                        role="tab"
                        onClick={() => handleViewInstructor(instructor.id)} // Add onClick event here
                      >
                        {instructor.user.name}
                      </Nav.Link>
                    ))}
                  </Nav>
                </Tab.Container>
              )}
              {userMeData && userMeData.instructor.position === "DIRECTOR" && (
                <>
                <Form.Check className="form-switch">
                  <Form.Check.Input type="checkbox" className="me-2" onChange={handleAllView} checked={allView} />
                  <Form.Check.Label htmlFor="trialCheckDefault">
                    전체 스케줄 보기
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="form-switch">
                  <Form.Check.Input type="checkbox" className="me-2" onChange={handleGroupLessonView} checked={groupLessonView} />
                  <Form.Check.Label htmlFor="trialCheckDefault">
                    그룹레슨만 보기
                  </Form.Check.Label>
                </Form.Check>
                </>
              )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">레슨 상세</Modal.Title>
          <span style={{color: '#fff'}}>{lessonData.lessonPrice}</span>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleScheduleEdit}>
            <div className="row">
              <Form.Group className="col-md-6 form-group">
                <Form.Label>레슨날짜</Form.Label>
                <div className="form-group">
                  <Flatpickr
                    options={{
                      dateFormat: "Y-m-d",
                      locale: Korean,
                      defaultDate: lessonData.lessonDate,
                    }}
                    className="form-control flatpickrdate"
                    placeholder="날짜를 선택해주세요."
                    onChange={handleDateChange}
                    required
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-md-6 form-group">
                <Form.Label>레슨시간</Form.Label>
                <div className="form-group">
                  <Flatpickr
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                      locale: "ko",
                      defaultDate: lessonData.lessonTime,
                    }}
                    className="form-control flatpickrtime"
                    placeholder="시간을 선택해주세요."
                    onChange={handleTimeChange}
                    required
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-md-6 form-group">
                <Form.Label htmlFor="typeSelect">레슨 종류</Form.Label>
                <select
                  className="form-select"
                  id="typeSelect"
                  name="lessonType"
                  value={lessonData.lessonType}
                  onChange={handleChange}
                  required
                >
                  <option value="">선택해주세요.</option>
                  {lessonTypes.map((type, index) => (
                    <option key={index} value={type.lessonType}>
                      {type.lessonTypeName}
                    </option>
                  ))}
                </select>
              </Form.Group>
              <Form.Group className="col-md-6 form-group">
                <Form.Label htmlFor="typeSelect">레슨 옵션</Form.Label>
                <select
                  className="form-select"
                  id="typeSelect"
                  name="lessonOption"
                  value={lessonData.lessonOption}
                  onChange={handleChange}
                >
                  <option value="">선택해주세요.</option>
                  {lessonOptions.map((option, index) => (
                    <option key={index} value={option.lessonOption}>
                      ({option.lessonTypeName}) {option.lessonOption}
                    </option>
                  ))}
                </select>
              </Form.Group>
              <Form.Group className="col-md-12 form-group">
                <Form.Label htmlFor="count">인원제한</Form.Label>
                <Form.Control
                  type="number"
                  name="count"
                  value={lessonData.count}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="col-md-12 form-group">
                <Form.Label htmlFor="count">레슨 상태</Form.Label>
                <select
                  className="form-select"
                  id="typeSelect"
                  name="status"
                  value={lessonData.status}
                  onChange={handleChange}
                >
                  <option value="OPEN">오픈</option>
                  <option value="FINISH">마감</option>
                </select>
              </Form.Group>
              <Form.Group className="col-md-12 form-group">
                <Form.Label>참여 회원</Form.Label>
                <Select
                  defaultValue={lessonData.selectUserData}
                  onChange={handleUserSelectedChange}
                  options={selectedOption}
                  placeholder="선택해주세요."
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isMulti
                />
              </Form.Group>
              <Form.Group className="col-md-12 form-group">
                <Form.Label>대기 회원</Form.Label>
                <Select
                  defaultValue={lessonData.waitUserData}
                  onChange={handleUserWaitChange}
                  options={selectedOption}
                  placeholder="선택해주세요."
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isMulti
                />
              </Form.Group>
              <Form.Group className="col-md-12 form-group">
                <Form.Label style={{color: '#c03221'}}>노쇼 회원</Form.Label>
                <Select
                  defaultValue={lessonData.noShowUserData}
                  onChange={handleUserNoShowChange}
                  options={selectedOption}
                  placeholder="선택해주세요."
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isMulti
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label htmlFor="lname">메모</Form.Label>
                <Form.Control
                  as="textarea"
                  name="memo"
                  value={lessonData.memo}
                  onChange={handleChange}
                  rows="5"
                />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleScheduleEdit}>
            저장
          </Button>
          <Button variant="danger" onClick={() => handleScheduleDelete(`${lessonData.id}`)}>
            삭제
          </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <Col lg="12">
          <Row>
            <Col lg="12" className="col-lg-12">
              <Card>
                <Card.Body>
                <FullCalendar
                  plugins={[dayGridPlugin, listPlugin, bootstrapPlugin]}
                  locale={koLocale}
                  headerToolbar={{
                    left: 'today',
                    center: 'prev,title,next',
                    right: 'dayGridMonth,dayGridWeek,dayGridDay,listWeek',
                  }}
                  //initialView={isMobile ? 'dayGridWeek' : 'dayGridWeek'}
                  events={transformedData}
                  eventClick={handleLessonDataShow}
                  // height={isMobile ? 'auto' : 'auto'}
                  // contentHeight={isMobile ? 'auto' : 'auto'}
                />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* 강사 메모 */}
          <Row>
            {instructorMemoList && instructorMemoList.map((data, index) => (
              <Col lg="4" key={data.id}>
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">
                        {data.user.name}님 메모 {" "}
                        {myInstructorId === data.instructorId && (
                          <Button type="button" variant="btn btn-sm btn-secondary" onClick={() => handleUpdateMemoShow(data.lessonMemo, data.id)}>
                            수정
                          </Button>
                        )}  
                      </h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                  <Form>
                    <Row>
                      <Col>
                        {/* 메모는 보여주기로 사용하고 수정 기능은 모달을 사용하자 */}
                        <Form.Group className="form-group">
                          <Form.Control
                            as="textarea"
                            name="memo"
                            value={data.lessonMemo}
                            disabled
                            rows="5"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                  </Card.Body>
                </Card>
              </Col>
            ))}
            <Modal show={memoShow} onHide={handleMemoClose}>
              <Modal.Header closeButton>
                <Modal.Title as="h5">메모 수정</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="col-md-12 form-group">
                    <Form.Control
                      as="textarea"
                      value={instructorMemo}
                      onChange={(e) => setInstructorMemo(e.target.value)}
                      rows="6"
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleUpdateInstructorMemo}>
                  저장
                </Button>
              </Modal.Footer>
            </Modal>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
});

ScheduleList.displayName = "ScheduleList";
export default ScheduleList;
