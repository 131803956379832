export const classificationMapping = {
  INDIVIDUAL: "개인",
  GROUP: "그룹",
  CONSULT: "상담"
};

export const joinPathMapping = {
  SIGN_BOARD: "간판",
  LEAFLET: "전단지",
  INTRODUCE: "지인소개",
  RE_JOIN: "재등록",
  HOMEPAGE: "홈페이지",
  INSTAGRAM: "인스타그램",
  BLOG: "블로그",
}

export const saleTypeMapping = {
  INITIAL: "신규",
  RENEWAL: "재등록",
  INTRODUCE: "소개",
}

export const instructorMapping = {
  BASIC: "강사",
  MANAGER: "관리자",
  DIRECTOR: "원장",
}