import React, { memo, Fragment, useState } from "react";

//react-bootstrap
import { Row, Col, Image, Form, Button, ListGroup } from "react-bootstrap";

//router
import { Link, useNavigate } from "react-router-dom";

//components
import Card from "../../../components/bootstrap/card";

// img
import facebook from "../../../assets/images/brands/fb.svg";
import google from "../../../assets/images/brands/gm.svg";
import instagram from "../../../assets/images/brands/im.svg";
import linkedin from "../../../assets/images/brands/li.svg";
import auth1 from "../../../assets/images/auth/01.png";
import logoImg from "../../../assets/images/logos/logo.png"

// Import selectors & action from setting store
import * as SettingSelector from "../../../store/setting/selectors";

// Redux Selector / Action
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
import { setAccessToken, setRefreshToken } from "../../../store/slices/authSlice";

// api
import { getUserMe, login } from '../../../api/auth';

const SignIn = memo(() => {
  const appName = useSelector(SettingSelector.app_name);
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const handleLogin = async () => {
    try {
      const data = await login(username, password);
      dispatch(setAccessToken(data.accessToken));
      dispatch(setRefreshToken(data.refreshToken)); // Assume the response contains a refresh token

      const user = await getUserMe();
      if (user.role === 'ROLE_USER') {
        navigate('/lesson/reservation');
      } else if (user.instructor.position === 'BASIC' || user.instructor.position === 'MANAGER') {
        navigate('/lesson/schedule-list');
      } else if (user.role === 'ROLE_MASTER' || user.role === 'ROLE_SUPERVISOR') {
        navigate('/store/store-list');
      } else {
        navigate('/');
      }
      
    } catch (error) {
      alert(error.message);
      setError(error.message);
    }
  };

  return (
    <Fragment>
      <section className="login-content">
        <Row className="m-0 align-items-center bg-white vh-100">
          <Col md="6">
            <Row className="justify-content-center">
              <Col md="10">
                <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                  <Card.Body>
                    <Link
                      className="navbar-brand d-flex align-items-center justify-content-center mb-3"
                    >
                      {/* <svg
                        width="30"
                        className="text-primary"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="-0.757324"
                          y="19.2427"
                          width="28"
                          height="4"
                          rx="2"
                          transform="rotate(-45 -0.757324 19.2427)"
                          fill="currentColor"
                        />
                        <rect
                          x="7.72803"
                          y="27.728"
                          width="28"
                          height="4"
                          rx="2"
                          transform="rotate(-45 7.72803 27.728)"
                          fill="currentColor"
                        />
                        <rect
                          x="10.5366"
                          y="16.3945"
                          width="16"
                          height="4"
                          rx="2"
                          transform="rotate(45 10.5366 16.3945)"
                          fill="currentColor"
                        />
                        <rect
                          x="10.5562"
                          y="-0.556152"
                          width="28"
                          height="4"
                          rx="2"
                          transform="rotate(45 10.5562 -0.556152)"
                          fill="currentColor"
                        />
                      </svg> */}
                      <Image src={logoImg} alt="" loading="lazy" />
                      {/* <h4 className="logo-title ms-3 mb-0">{appName}</h4> */}
                    </Link>
                    {/* <h2 className="mb-2 text-center">로그인</h2> */}
                    <p className="text-center">로그인 후 이용가능합니다.</p>
                    <Form>
                      <Row>
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="email" className="">
                              이메일
                            </Form.Label>
                            <Form.Control
                              type="email"
                              className=""
                              id="email"
                              aria-describedby="email"
                              placeholder=" "
                              onChange={(e) => setUsername(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="password">
                              비밀번호
                            </Form.Label>
                            <Form.Control
                              type="password"
                              id="password"
                              aria-describedby="password"
                              placeholder=" "
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        {/* <Col lg="12" className="d-flex justify-content-between">
                          <Form.Check className="form-check mb-3">
                            <Form.Check.Input
                              type="checkbox"
                              id="customCheck1"
                            />
                            <Form.Check.Label htmlFor="customCheck1">
                              Remember Me
                            </Form.Check.Label>
                          </Form.Check>
                        </Col> */}
                      </Row>
                      <div className="d-flex justify-content-center">
                        <Button
                          onClick={handleLogin}
                          type="button"
                          variant="btn btn-primary"
                        >
                          로그인
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <div className="sign-bg">
              {/* <svg
                width="280"
                height="230"
                viewBox="0 0 431 398"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.05">
                  <rect
                    x="-157.085"
                    y="193.773"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 -157.085 193.773)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="7.46875"
                    y="358.327"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 7.46875 358.327)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="61.9355"
                    y="138.545"
                    width="310.286"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 61.9355 138.545)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="62.3154"
                    y="-190.173"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 62.3154 -190.173)"
                    fill="#3B8AFF"
                  />
                </g>
              </svg> */}
            </div>
          </Col>
          <Col
            md="6"
            className="d-md-block d-none bg-primary p-0 vh-100 overflow-hidden"
          >
            <Image
              src={auth1}
              className="img-fluid gradient-main animated-scaleX"
              alt="images"
            />
          </Col>
        </Row>
      </section>
    </Fragment>
  );
});

SignIn.displayName = "SignIn";
export default SignIn;
