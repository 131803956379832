import React, { memo, Fragment, useState, useEffect } from "react";

//react-bootstrap
import { Row, Col, Button, Modal, Form, Table, Badge } from "react-bootstrap";
import Select from "react-select";

//components
import Card from "../../../components/bootstrap/card";

//full calendar
import FullCalendar from "@fullcalendar/react";
import koLocale from "@fullcalendar/core/locales/ko";

//lib
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import * as moment from "moment";

import useLessonUserLists from "../../../hooks/lesson/useLessonUserLists";
import { cancelLesson, getLessonUser, reservationLesson, waitingLesson } from "../../../api/lesson";
import { getUserMe } from "../../../api/auth";

import './reservation.css'
import useConfigSystem from "../../../hooks/config/useConfigSystem";

const LessonReservation = memo(() => {
  const [show, setShow] = useState(false);
  const [storeId, setStoreId] = useState(null);
  const [userInfoData, setUserInfoData] = useState(null);
  const [userProgramData, setUserProgramData] = useState(null);
  const [systemData, setSystemData] = useState(null);
  const [transformedData, setTransformedData] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [lessonReserve, setLessonReserve] = useState(false);
  const [lessonFull, setLessonFull] = useState(false);
  const [lessonData, setLessonData] = useState({
    instructorId: "",
    storeId: "",
    count: 0,
    lessonType: "",
    lessonTypeName: "",
    lessonOption: "",
    lessonDate: "",
    lessonTime: "",
    lessonUserList: [],
    selectUserData: [],
  });
  const [reservationCount, setReservationCount] = useState(0);
  const [waitingCount, setWaitingCount] = useState(0);
  const [myLogStatus, setMyLogStatus] = useState('');
  const [isRequest, setIsRequest] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = String(now.getMonth() + 1).padStart(2, "0");
  const currentDay = String(now.getDate()).padStart(2, "0");
  const currentHours = String(now.getHours()).padStart(2, "0");
  const currentMinutes = String(now.getMinutes()).padStart(2, "0");
  const currentDateTimeString = `${currentYear}-${currentMonth}-${currentDay} ${currentHours}:${currentMinutes}`;

  const searchDate = `${currentYear}-${currentMonth}`;
  const { lessonList } = useLessonUserLists(storeId, searchDate);

  const handleClose = () => {
    setShow(false);
    setLessonReserve(false);
    setLessonFull(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    const transformLessonLists = (lessonLists) => {
      const transformedData = lessonLists.map((lesson) => {
        let backgroundColor, textColor, borderColor;
        backgroundColor = "rgba(26, 160, 83, 1)";
        textColor = "rgba(255, 255, 255, 1)";
        borderColor = "rgba(26, 160, 83, 1)";
    
        if (lesson.status === 'FINISH') {
          backgroundColor = "rgba(0, 31, 77, 1)";
          textColor = "rgba(255, 255, 255, 1)";
          borderColor = "rgba(0, 31, 77, 1)";
        } else if (lesson.count <= lesson.logCount) {
          backgroundColor = "rgba(241, 106, 27, 1)";
          textColor = "rgba(255, 255, 255, 1)";
          borderColor = "rgba(241, 106, 27, 1)";
        }
        
        return {
          id: lesson.id,
          // title: isMobile ? `${lesson.lessonTime}` : `${lesson.userName} - ${lesson.lessonTime}`,
          title: lesson.lessonTime,
          start: moment(lesson.lessonDate).format("YYYY-MM-DD"),
          end: moment(lesson.lessonDate).format("YYYY-MM-DD"),
          backgroundColor: backgroundColor,
          textColor: textColor,
          borderColor: borderColor,
        };        
      });
  
      return transformedData;
    };

    if (storeId != null && lessonList) {
      const newData = transformLessonLists(lessonList);
      setTransformedData(newData);
    }
  }, [lessonList, storeId, isMobile]);

  // 사용자 정보 조회
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserMe();
        setUserInfoData(userData);
        setUserProgramData(userData.userProgram);
        setSystemData(userData.configSystem)
        setStoreId(userData.store.id);

        // 그룹레슨 기간에 따른 상태값 정의
        const currentTime = new Date();
        const groupStartDate = new Date(userData.userProgram.groupStartDate + "T00:00:00");
        const groupEndDate = new Date(userData.userProgram.groupEndDate + "T23:59:59");

        if (currentTime < groupStartDate || currentTime > groupEndDate) {
          setIsActive(false);
        } else {
          setIsActive(true);
        }
      } catch (error) {
        console.error("Failed to fetch user data", error);
      }
    };
    fetchUserData();
  }, [isRequest]);

  // 레슨 데이터 모달에 연결
  const handleLessonDataShow = (info) => {
    const eventId = info.event.id;
    const fetchLessonDetailData = async (eventId) => {
      try {
        const lesson = await getLessonUser(eventId);
        lesson.selectUserData = lesson.lessonUserList.map((user) => ({
          value: user.id, // 예시: 유저의 id를 value로 사용할 수 있음
          label: user.name, // 예시: 유저의 이름을 label로 사용할 수 있음
        }));
        setLessonData(lesson);

        let lessonReservationCount = 0;
        let lessonWaitingCount = 0;
        lesson.lessonUserList.forEach((row) => {
          if (row.id === userInfoData.id) {
            setLessonReserve(true);
            if (row.lessonLog.status === 'RESERVE') {
              setMyLogStatus('예약');
            } else {
              setMyLogStatus('대기');
            }
          }
        
          switch (row.lessonLog.status) {
            case 'RESERVE': lessonReservationCount++; break;
            case 'WAIT': lessonWaitingCount++; break;
            default: break;
          }
        });
        setReservationCount(lessonReservationCount);
        setWaitingCount(lessonWaitingCount);

        if (lessonReservationCount === lesson.count) {
          setLessonFull(true);
        }

        handleShow(true);
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };
    setIsRequest(false);
    fetchLessonDetailData(eventId);
  };

  // 레슨예약
  const handleReservation = async (e) => {
    e.preventDefault();
    if (!isActive) {
      alert("그룹레슨 이용기간이 종료되었습니다.");
      return false;
    }

    if (userProgramData.groupCount < 1) {
      alert("신청가능한 횟수가 부족합니다.");
      return false;
    }

    try {
      await reservationLesson(userInfoData.id, lessonData.id);
      alert("신청되었습니다.");
      setIsRequest(true);
      handleClose();
    } catch (error) {
      alert(error.message);
    }
  };

  const handleWaiting = async (e) => {
    e.preventDefault();
    if (!isActive) {
      alert("그룹레슨 이용기간이 종료되었습니다.");
      return false;
    }
    
    if (userProgramData.groupCount < 1) {
      alert("신청가능한 횟수가 부족합니다.");
      return false;
    }

    try {
      await waitingLesson(userInfoData.id, lessonData.id);
      alert("신청되었습니다.");
      setIsRequest(true);
      handleClose();
    } catch (error) {
      alert(error.message);
    }
  }

  // 레슨예약 취소
  const handleCancel = async (e) => {
    e.preventDefault();

    const lessonDateTimeString = `${lessonData.lessonDate} ${lessonData.lessonTime}`;
    const lessonDateTime = new Date(lessonDateTimeString);
    const currentDateTime = new Date(currentDateTimeString);
    
    // 시간 차이
    const timeDifference = lessonDateTime - currentDateTime; // in milliseconds

    // 시간 단위로 변경
    const timeDifferenceInHours = timeDifference / (1000 * 60 * 60);

    // 지점 취소 마감시간
    const groupLessonUserDeadline = systemData.groupLessonUserDeadline

    if (myLogStatus === '예약' && timeDifferenceInHours < groupLessonUserDeadline) {
      alert('수업취소는 당일 ' + groupLessonUserDeadline + '시간전까지 가능합니다.');
      return false;
    }

    try {
      await cancelLesson(userInfoData.id, lessonData.id);
      alert("취소되었습니다.");
      setIsRequest(true);
      handleClose();
    } catch (error) {
      alert(error.message);
    }
  };

  function getKoreanDay(englishDay) {
    if (!englishDay) {
      return ''; // 기본값 설정 (필요에 따라 수정 가능)
    }

    const days = {
      MONDAY: '월요일',
      TUESDAY: '화요일',
      WEDNESDAY: '수요일',
      THURSDAY: '목요일',
      FRIDAY: '금요일',
      SATURDAY: '토요일',
      SUNDAY: '일요일',
    };
    return days[englishDay.toUpperCase()] || englishDay;
  }

  function getFormattedTime(time) {
    if (!time) {
      return ''; // 기본값 설정 (필요에 따라 수정 가능)
    }
    const [hour, minute] = time.split(':').map(Number);
    const isPM = hour >= 12;
    const formattedHour = hour % 12 || 12; // 12시간제 변환
    const period = isPM ? '오후' : '오전';
    return `${period} ${formattedHour}시${minute.toString().padStart(2, '0')}분`;
  }

  // 모바일 Size
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const mobileToolbar = {
    left: 'today',
    center: 'prev,title,next',
    right: ''
  };
  const desktopToolbar = {
    left: 'today',
    center: 'prev,title,next',
    right: 'dayGridMonth,dayGridWeek,dayGridDay,listWeek'
  };

  return (
    <Fragment>
      <Row>
        <Col md="12">
          {/* <Card>
            <Card.Body className="d-flex">
              <h6 className="mb-0">{userInfoData && userInfoData.name + '님'} 반갑습니다.</h6>
            </Card.Body>
          </Card> */}
          <Card>
            <Card.Body className="d-flex justify-content-between align-items-center flex-wrap gap-2">
              <div className="card-title mb-0">
                <h4 className="mb-0">{userInfoData && userInfoData.store.name + '지점'} 그룹레슨 스케줄</h4>
                <Badge pill bg="success">예약가능</Badge>{" "}
                <Badge pill bg="secondary">예약불가</Badge>{" "}
                <Badge pill bg="warning">인원마감</Badge>{" "}
              </div>
              <div>
                {/* <h4 className="mb-0">개인: {userProgramData && userProgramData.individualCount}</h4> */}
                <h6 className="mb-0">이용기간: {userProgramData && `${userProgramData.groupStartDate}~${userProgramData.groupEndDate}`}</h6>
                <h6 className="mb-0">잔여횟수: {userProgramData && userProgramData.groupCount}</h6>
              </div>
            </Card.Body>
            <Card.Body>
              - 기간 내 이용치 않으실 경우 남은 레슨이 자동 소멸합니다.<br/>
              - 수업취소는 당일 {systemData?.groupLessonUserDeadline}시간전까지 가능합니다.<br/>
              - 그룹레슨 신청은 전주 {getKoreanDay(systemData?.groupLessonDayWeek)} {getFormattedTime(systemData?.groupLessonOpenTime)} 오픈되어 자동선착순 신청가능합니다. 
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">레슨 신청</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="mb-0">
            {/* <Card.Header>
              <div className="header-title">
                <h4 className="card-title">⭐️ 레슨 정보 ⭐️</h4>
              </div>
            </Card.Header> */}
            <Card.Body>
              <div className="twit-feed">
                <div className="d-flex align-items-center mb-4">
                  <div className="media-support-info">
                    <h6 className="mb-0">
                      레슨 날짜: {lessonData.lessonDate} {lessonData.lessonTime}
                    </h6>
                  </div>
                </div>
                <div className="media-support-body" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    담당 강사님: {lessonData.instructorName}
                  </div>
                  <div>
                    <span className="text-danger">예약 {reservationCount}/{lessonData.count}</span> 대기 {waitingCount}/3
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <div>
            {lessonData.status === "FINISH" ? (
              "마감된 레슨입니다"
            ) : lessonReserve ? (
              <Button variant="warning" onClick={handleCancel}>
                {myLogStatus} 취소하기
              </Button>
            ) : lessonFull && waitingCount < 3 ? (
              <>
              <Button variant="secondary" disabled>
                예약마감
              </Button>{" "}
              <Button variant="primary" onClick={handleWaiting}>
                대기하기
              </Button>
              </>
            ) : lessonFull && waitingCount === 3 ? (
              <>
              <Button variant="secondary" disabled>
                예약마감
              </Button>{" "}
              <Button variant="primary" disabled>
                대기마감
              </Button>
              </>
            ) : (
              <Button variant="primary" onClick={handleReservation}>
                예약하기
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <Row>
        <Col lg="12">
          <Row>
            <Col lg="12" className="col-lg-12">
              <Card>
                <Card.Body>
                  <FullCalendar
                    plugins={[dayGridPlugin, listPlugin, bootstrapPlugin]}
                    locale={koLocale}
                    headerToolbar={isMobile ? mobileToolbar : desktopToolbar}
                    events={transformedData}
                    eventClick={handleLessonDataShow}
                    height={isMobile ? "auto" : null}
                    contentHeight={isMobile ? "auto" : null}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
});

LessonReservation.displayName = "LessonReservation";
export default LessonReservation;
