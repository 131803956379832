import { useState, useEffect } from 'react';
import { getInstructorLists } from '../api/instructor';

const useInstructorLists = (storeId, myInstructorId, isSchedule) => {
  const [instructorList, setInstructorList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (storeId) {
          const data = await getInstructorLists(storeId, myInstructorId, isSchedule);
          setInstructorList(data);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [storeId, myInstructorId, isSchedule]);

  return { instructorList, loading, error };
};

export default useInstructorLists;
