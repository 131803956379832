import { Button, Form } from "react-bootstrap";
import Card from "../../../../components/bootstrap/card";
import Select from "react-select";
import $ from "jquery"; // jQuery 필요

//flatpicker
import Flatpickr from "react-flatpickr";
import { Korean } from "flatpickr/dist/l10n/ko.js"; // Import 한국어 locale

const UserEditRightForm = ({
  formData,
  programList,
  handleProgramChange,
  handleDateChange,
  handleInstructorSelectedChange,
  lessonOptions,
  instructorListOption,
  selectInstructor,
  flatpickrRef,
  resetDates
}) => (
  <div className="col-md-6">
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">프로그램 정보</h4>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="new-user-info">
          <Form>
            <div className="row">
              <Form.Group className="col-md-12 form-group">
                <Form.Label htmlFor="userList">담당 강사</Form.Label>
                <Select
                  value={
                    selectInstructor
                      ? selectInstructor
                      : instructorListOption.find(
                          (option) => option.value === formData.selectInstructorId
                        )
                  }
                  isClearable
                  onChange={handleInstructorSelectedChange}
                  options={instructorListOption}
                  placeholder="선택해주세요."
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </Form.Group>
              <Form.Group className="col-md-12  form-group">
                <Form.Label>프로그램 선택</Form.Label>
                <select
                  className="form-select"
                  name="programId"
                  value={formData.userProgram?.programId ?? ""}
                  onChange={handleProgramChange}
                >
                  <option value="">선택해주세요.</option>
                  {programList && programList.map((row, index) => (
                    <option key={index} value={row.id}>
                      {row.name}
                    </option>
                  ))}
                </select>
              </Form.Group>
              <Form.Group className="col-md-12 form-group">
                <Form.Label>옵션</Form.Label>
                <select
                  className="form-select"
                  name="individualType"
                  value={formData.userProgram?.individualType ?? ""}
                  onChange={handleProgramChange}
                >
                  <option value="">선택해주세요.</option>
                  {lessonOptions && lessonOptions.map((option, index) => (
                    <option key={index} value={option.lessonOption}>
                      {option.lessonOption}
                    </option>
                  ))}
                </select>
              </Form.Group>
              <Form.Group className="col-md-12 form-group mb-2">
                <Form.Label>개인 이용기간</Form.Label>
                <div className="form-group">
                  {formData.userProgram && (
                    <Flatpickr
                      key={`${formData.userProgram.individualStartDate}-${formData.userProgram.individualEndDate}`} // 값이 변경됨에 따라 re-rendering
                      options={{
                        mode: "range",
                        dateFormat: "Y-m-d",
                        locale: Korean,
                        defaultDate: [
                          formData.userProgram.individualStartDate ?? "",
                          formData.userProgram.individualEndDate ?? "",
                        ],
                        onReady: function (dateObj, dateStr, instance) {
                          var $cal = $(instance.calendarContainer);
                          if ($cal.find('.flatpickr-clear').length < 1) {
                            $cal.append('<button class="btn btn-sm btn-primary flatpickr-clear">날짜 초기화</button>'); // Clear 버튼 추가
                            $cal.find('.flatpickr-clear').on('click', function () {
                              instance.clear(); // 날짜 초기화
                              instance.close(); // 캘린더 닫기

                              handleDateChange(null, 'individual', true)
                            });
                          }
                        },
                      }}
                      className="form-control flatpickrdate"
                      placeholder="날짜를 선택해주세요."
                      onChange={(selectedDates) => handleDateChange(selectedDates, 'individual')}
                      required
                    />
                  )}
                </div>
              </Form.Group>
              <Form.Group className="col-md-12 form-group">
                <Form.Label htmlFor="add1">개인횟수</Form.Label>
                <Form.Control
                  type="number"
                  name="individualCount"
                  value={formData.userProgram?.individualCount ?? ""}
                  onChange={handleProgramChange}
                  onWheel={(e) => e.currentTarget.blur()}
                  required
                />
              </Form.Group>
              <Form.Group className="col-md-12 form-group mb-2">
                <Form.Label>그룹 이용기간</Form.Label>
                <div className="form-group">
                  {formData.userProgram && (
                    <Flatpickr
                      key={`${formData.userProgram.groupStartDate}-${formData.userProgram.groupEndDate}`} // 값이 변경됨에 따라 re-rendering
                      options={{
                        mode: "range",
                        dateFormat: "Y-m-d",
                        locale: Korean,
                        defaultDate: [
                          formData.userProgram.groupStartDate ?? "",
                          formData.userProgram.groupEndDate ?? "",
                        ],
                        onReady: function (dateObj, dateStr, instance) {
                          var $cal = $(instance.calendarContainer);
                          if ($cal.find('.flatpickr-clear').length < 1) {
                            $cal.append('<button class="btn btn-sm btn-primary flatpickr-clear mb-3">날짜 초기화</button>'); // Clear 버튼 추가
                            $cal.find('.flatpickr-clear').on('click', function () {
                              instance.clear(); // 날짜 초기화
                              instance.close(); // 캘린더 닫기

                              handleDateChange(null, 'group', true)
                            });
                          }
                        },
                      }}
                      className="form-control flatpickrdate"
                      placeholder="날짜를 선택해주세요."
                      onChange={(selectedDates) => handleDateChange(selectedDates, 'group')}
                      required
                    />
                  )}
                </div>
              </Form.Group>
              <Form.Group className="col-md-12 form-group">
                <Form.Label htmlFor="add1">그룹횟수</Form.Label>
                <Form.Control
                  type="number"
                  name="groupCount"
                  value={formData.userProgram?.groupCount ?? ""}
                  onChange={handleProgramChange}
                  onWheel={(e) => e.currentTarget.blur()}
                  required
                />
              </Form.Group>
              <hr/>
              <Form.Group className="form-group">
                <Form.Check className="form-switch">
                  <Form.Check.Input
                    type="checkbox"
                    className="me-2"
                    id="trialCheckDefault"
                    name="isWeekGroup"
                    checked={formData.userProgram?.isWeekGroup ?? false}
                    onChange={handleProgramChange}
                  />
                  <Form.Check.Label htmlFor="trialCheckDefault">
                    주N회 그룹레슨인 경우 체크해주세요.
                  </Form.Check.Label>
                </Form.Check>
              </Form.Group>
            </div>
          </Form>
        </div>
      </Card.Body>
    </Card>
  </div>
);

export default UserEditRightForm;
